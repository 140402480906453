.c_form {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.c_input {
    width: 100%;
    height: 40px;
    background-color: #eeeeee;
    border: 1px solid #bdbdbd;
    color: #000000;
    font-size: 16px;
    box-sizing: border-box;
    transition: all 0.3s ease;
    margin: 0px;
    padding: 0px 10px;
}
.phone_input {
    width: calc(100% - 100px);
    height: 40px;
    background-color: #eeeeee;
    border: 1px solid #bdbdbd;
    color: #000000;
    font-size: 16px;
    box-sizing: border-box;
    transition: all 0.3s ease;
    margin: 0px;
    padding: 0px 10px;
}
label {
    color: #000000;
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
}
.input_label_error {
    color: #fd6161;
}
.country-search-box {
    height: 35px;
    background-color: transparent;
    border: none;
}
.country-search-box {
    outline: none;
}


/* input[type=checkbox] {
    accent-color: #f84370;
} */

select {
    /* width: 100%;
    height: 60px;
    border: none;
    background-color: #ebeaea;
    color: #000000;
    font-size: 16px;
    box-sizing: border-box;
    transition: all 0.3s ease;
    margin: 0px; */
    padding: 0px 20px;
}
/* select option {
    color: red;
} */
textarea {
    width: 100%;
    background-color: #eeeeee;
    border: 1px solid #bdbdbd;
    color: #000000;
    font-size: 16px;
    box-sizing: border-box;
    transition: all 0.3s ease;
    margin: 0px;
    padding: 0px 10px;
}
/* .c_form_btn{
    width: max-content;
    padding: 15px 20px;
    margin-top: 20px;
    background-color: #000000;
    color: #ffffff;
    font-size: 14px;
    font-weight: 900;
    text-transform: uppercase;
    border: none;
    transition: all 0.3s ease;
} */
.form_error_msg {
    font-size: 14px;
    padding: 3px;
    color: rgb(248, 40, 40);
    display: none;

}

.c_input:focus, .phone_input:focus, textarea:focus{
    outline: none;
    border: 1px solid #B8F904;
}

/* .main_input:invalid[focused="true"]{
    border: 1px solid red;
}
.main_input:invalid[focused="true"] ~ span{
    display: block;
}
@media only screen and (max-width: 768px) {
    .c_form_btn{
        width: 100%;
    }
  } */

  /* Toggle Switch */

  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 25px;
    cursor: pointer;
    /* border: 1px solid red; */
  }
  
  .toggle-switch input[type="checkbox"] {
    display: none;
  }
  
  .toggle-switch-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ddd;
    border-radius: 20px;
    box-shadow: inset 0 0 0 2px #ccc;
    transition: background-color 0.3s ease-in-out;
  }
  
  .toggle-switch-handle {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
  }
  
  .toggle-switch::before {
    content: "";
    position: absolute;
    top: -25px;
    right: -35px;
    font-size: 12px;
    font-weight: bold;
    color: #aaa;
    text-shadow: 1px 1px #fff;
    transition: color 0.3s ease-in-out;
  }
  
  .toggle-switch input[type="checkbox"]:checked + .toggle-switch-handle {
    transform: translateX(45px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 3px #05c46b;
  }
  
  .toggle-switch input[type="checkbox"]:checked + .toggle-switch-background {
    background-color: #05c46b;
    box-shadow: inset 0 0 0 2px #04b360;
  }
  
  .toggle-switch input[type="checkbox"]:checked + .toggle-switch:before {
    content: "On";
    color: #05c46b;
    right: -15px;
  }
  
  .toggle-switch input[type="checkbox"]:checked + .toggle-switch-background .toggle-switch-handle {
    transform: translateX(35px);
  }

.copy_url {
  width: 100%;
  height: 100;
  background-color: transparent;
  border: none;
  color: #9e9e9e;;
  font-size: 14px;
  padding-left: 5px;
  /* padding: 0px 10px; */
}
.copy_url:focus {
  outline: none;
  border: none;
}