/* body {
    margin: 0;
  }
  .container {
    align-items: center;
    background: #202020;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100%;
  } */
  .button {
    padding: 0;
    outline: none;
    cursor: pointer;
  }
  .button:focus {
    outline: none;
  }
  button::-moz-focus-inner {
    border: 0;
  }
  .button.is-text {
    background-color: transparent;
    border: 0;
  }
  .button-inner-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  #menu-button {
    width: 48px;
    height: 48px;
    transition: background-color 2s ease;
  }
  /* Menu toggle button */
  .icon {
      line-height: 0;
  }
  .menu-icon {
    text-align: right;
    width: 27px;
  }
  .menu-icon::after, .menu-icon::before {
    background-color: rgb(0, 0, 0);
    content: "";
    display: inline-block;
    height: 3px;
    width: 27px;
    margin: 3px 0;
    transition-duration: 0.5s;
  }
  .menu-icon::before {
    width: 27px;
  }
  .menu-icon::after {
    width: 18px;
  }
  #menu-button:hover .menu-icon::before {
    width: 18px;
    transition-duration: 0.5s;
  }
  #menu-button:hover .menu-icon::after{
    width: 27px;
    transition-duration: 0.5s;
  }
  #menu-button.is-opened .menu-icon::before {
    transform: rotateZ(135deg) translate(3px, -3px);
    width: 27px;
    background-color: #fff;
  }
  #menu-button.is-opened .menu-icon::after {
    transform: rotateZ(-135deg) translate(3px, 3px);
    width: 27px;
    background-color: #fff;
  }
  .react-reveal {
    animation-fill-mode: backwards !important;
  }