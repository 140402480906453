
.right_wrap {
    width: 100%;
    margin: 0px 20px 20px 20px;
    padding: 20px;
    background: rgba(255,255,255,0.1);
    backdrop-filter: blur(5px);
}

input[type=checkbox] {
    accent-color: #B8F904;
    font-size: 14px;;
    margin-right: 10px;
    margin-top: 3px;
}
.no_acct {
  color: #000000;
  font-size: 15px;
  transition: all 0.3s ease;
}
.no_acct:hover {
  color: #0000FD;
}
.login_inputs {
    width: 100%;
    height: 40px;
    background-color: #eeeeee;
    border: 1px solid #bdbdbd;
    color: #000000;
    font-size: 16px;
    box-sizing: border-box;
    transition: all 0.3s ease;
    margin: 0px;
    padding: 0px 10px;
}
.login_password_input{
    width: calc(100% - 40px);
    height: 40px;
    background-color: #eeeeee;
    border-right: none;
    border-left: 1px solid #bdbdbd;
    border-top: 1px solid #bdbdbd;
    border-bottom: 1px solid #bdbdbd;
    color: #000000;
    font-size: 16px;
    box-sizing: border-box;
    transition: all 0.3s ease;
    margin: 0px;
    padding: 0px 10px;
}
input:focus {
    outline: none;
    border: 1px solid #757575;
}
@media only screen and (max-width: 768px) {
    .main_input {
        height: 50px;
    }
}


.loader {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    margin:15px auto;
    position: relative;
    color: #000;
    box-sizing: border-box;
    animation: animloader 1s linear infinite alternate;
  }
  
  @keyframes animloader {
    0% {
      box-shadow: -38px -6px, -14px 6px,  14px -6px;
    }
    33% {
      box-shadow: -38px 6px, -14px -6px,  14px 6px;
    }
    66% {
      box-shadow: -38px -6px, -14px 6px, 14px -6px;
    }
    100% {
      box-shadow: -38px 6px, -14px -6px, 14px 6px;
    }
  }


  .register_inputs {
    width: 100%;
    height: 30px;
    background-color: #eeeeee;
    border: 1px solid #bdbdbd;
    color: #000000;
    font-size: 16px;
    box-sizing: border-box;
    transition: all 0.3s ease;
    margin: 0px;
    padding: 0px 10px;
}
.register_password_inputs {
  width: calc(100% - 40px);
  height: 30px;
  background-color: #eeeeee;
  border-right: none;
  border-left: 1px solid #bdbdbd;
  border-top: 1px solid #bdbdbd;
  border-bottom: 1px solid #bdbdbd;
  color: #000000;
  font-size: 16px;
  box-sizing: border-box;
  transition: all 0.3s ease;
  margin: 0px;
  padding: 0px 10px;
}
.register_labels {
  color: #272727;
  font-size: 15px;
  font-weight: 300;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.terms{
  color: #000;
}

.new_link{
  color: #0000FD;
  font-weight: 400;
}

.scan_code {
  font-size: 25px;
  font-weight: 400;
}
.logo_klustaa {
  font-size: 21px;
  font-weight: 600;
}
.asset_name {
  text-transform: capitalize;
}